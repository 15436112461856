/* Your app custom styles here */


body,
div,
p,
a {
    font-family: 'Quicksand', sans-serif;
    font-weight: 800;
    font-size: 1rem !important;
}


.navbar .title, .list .item-title {
  font-weight: 800;
}

.font-family-quicksand {
  font-family: 'Quicksand', sans-serif;
}

.text-color-w {
  color: #fff !important;
}


.navbar a {
  color: #fff;
}

.device-desktop .block {
    width: 95%;
    margin: 10px auto;
  }
  
  .fab-center-center.left {
    top: 70%;
    left: 20%;
    transform: translateX(-50%) translateY(-50%);
  }
  
  .device-desktop .block.stats-block{
    width: 70%;
    margin: 10px auto;
  }
  
  .icon-normal {
    width:50px;
    height: 50px;
  }
  
  .icon-large {
    width:100px;
    height: 100px;
  }

  .btn-centered {
    width: 200px;
    text-align: center;
    margin: 0 auto;
  }

  .btn-centered-contact {
    width: 300px;
    text-align: center;
    margin: 0 auto;
  }

  
.btn-menu {
    height: 50px;
    font-size: 18px;
    font-weight: 800;
    color: #ffffff;
    background: rgb(131, 58, 180);
    background: linear-gradient(to right, rgba(131, 58, 180, 1), rgba(253, 29, 29, 1) 100%, rgba(17, 39, 225, 1))
  }
  

  .fab-centered {
    margin: 0 auto;
    text-align: center;
  }
  
  .text-centered {
    text-align: center;
  }
  
  .font-1rem {
    font-size: 1rem !important;
  }
  
  .font-15rem {
    font-size: 1.5rem !important;
  }
  
  .font-18rem {
    font-size: 1.8rem !important;
  }
  
  .font-2rem {
    font-size: 2rem !important;
  }

  img.fab-icon {
    width: 48px;
  }

  img.fab-icon-home {
    width: 108px;
  }

  img.fab-icon-contact {
    width: 48px;
  }

  .line-height-35 {
    line-height: 35px;
  }
  
  .line-height-25 {
    line-height: 25px;
  }

  .color-white {
    color: #fff !important;
    margin-bottom: 0px !important;
  }
  
  .font-weight-200 {
    font-weight: 200;
  }

  .font-weight-400 {
    font-weight: 400;
  }

  .font-weight-800 {
    font-weight: 800;
  }

  .ppage-content.home:before {
    content:"";
    position:absolute;
    top:0;
    left:0;
    bottom:0;
    right:20%;
    background:rgba(239, 126, 34, 0.5);
    -webkit-clip-path: polygon(0 0, 0% 100%, 100% 100%);
    clip-path: polygon(0 0, 0% 100%, 100% 100%);
  } 
  
  .page-home {

  }


  .fab-center-center-home {
    top: -10%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  .stroke-black {
    background: rgba(0, 0, 0, 0.712);
    //background: #ffcb11;
    border-radius: 5px;
    padding:15px;
  }
  
  .page-home {
    background-image:
    linear-gradient(to bottom, rgba(5, 5, 5, 0.12), rgba(7, 7, 7, 0.73)),
    url('../static/bg/1.jpg');
    background: url('../static/bg/1.jpg');
    background-size: cover;
    background-position: center;
  }

    
  .page-contact {
    background-image:
    linear-gradient(to bottom, rgba(5, 5, 5, 0.12), rgba(7, 7, 7, 0.73)),
    url('../static/bg/1.jpg');
    background: url('../static/bg/2.jpg');
    background-size: cover;
    background-position: center;
  }
  
  .page-home:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(75, 73, 73, 0.1);
    background-image: radial-gradient(black 33%, transparent 33%);
    background-size: 2px 2px;
  }

  .page-contact:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(75, 73, 73, 0.1);
    background-image: radial-gradient(black 33%, transparent 33%);
    background-size: 2px 2px;
    }

  .navbar-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 0;
    background: var(--f7-navbar-bg-color);
    background-image: var(--f7-navbar-bg-image, var(--f7-bars-bg-image));
    transition-property: transform;
    background: rgb(2, 0, 36);
    background: #ffcb11;
    //background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(22, 22, 22, 1) 0%, rgba(181, 181, 181, 1) 100%);
    color: #fff;
  }


.fab-car {
    width: 300px;
}

.center {
  margin: 0 auto;
  text-align: center;
}

.fab-number {
  width: 240px;
}


.fab-slogan-top {
    width: 200px;
}

.fab-slogan {
    width: 200px;
}


.fab-center-bottom {
    bottom: 0px;
    left: 50%;
    width: 100%;
    transform: translateX(-50%) translateY(-50%);
  }

  
.toolbar-inner .navbar {
    color: #444 !important;
  }
  
  .toolbar-bottom .navbar {
    color: rgb(239, 125, 23);
    font-size: 1rem;
    text-align: center;
    background: rgb(2, 0, 36);
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(22, 22, 22, 1) 0%, rgba(181, 181, 181, 1) 100%);
    color: #fff;
    line-height: 57px;
  }
  
  
* { box-sizing: border-box; }

body { font-family: sans-serif; }

/* ---- grid ---- */

.grid {
  background: transparent;
  margin: 0 auto; /* centered */
  visibility: hidden;
}

/* clear fix */
.grid:after {
  content: '';
  display: block;
  clear: both;
}

/* ---- .grid-item ---- */

.grid-item {
  float: left;
  width: 250px;
  height: 320px;
  background: transparent;
  border: 0px solid #333;
  border-color: hsla(0, 0%, 0%, 0.7);
  margin-top: 5px;
}

.grid-item--height2 { height: 320px; }



/* ---- .grid-item background ---- */

.grid-item.outfit {
  background: rgb(2, 0, 36);
  background: radial-gradient(circle, rgba(2, 0, 36, 1) 0%, rgba(251, 0, 0, 1) 0%, rgba(8, 0, 0, 1) 100%);
}

.grid-item.pickaxe {
  background: rgb(2, 0, 36);
  background: radial-gradient(circle, rgba(2, 0, 36, 1) 0%, rgba(251, 0, 0, 1) 0%, rgba(8, 0, 0, 1) 100%);
}

.grid-item.backpack {
  background: rgb(2, 0, 36);
  background: radial-gradient(circle, rgba(2, 0, 36, 1) 0%, rgba(161, 213, 249, 1) 0%, rgba(4, 122, 189, 1) 100%);
}

.grid-item.glider,
.grid-item.emote,
.grid-item.wrap,
.grid-item.music,
{
  background: rgb(2, 0, 36);
  background: radial-gradient(circle, rgba(2, 0, 36, 1) 0%, rgba(161, 213, 249, 1) 0%, rgba(4, 122, 189, 1) 100%);
}


.grid-item.bundle {
  background: rgb(8, 8, 8);
  background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(9, 59, 121, 1) 0%, rgba(0, 212, 255, 1) 100%);
}


/* ---- .grid-item background ---- */


/* ---- .grid-item footer ---- */

.grid-item .grid-footer {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 65px;
  padding: 0px;
  color: #fff;
}

.grid-item .grid-footer .top {
  position: relative;
  left: 0px;
  bottom: 0px;
  background: rgba(5, 5, 5, 0.69);
  display: inline-block;
  width: 100%;
  height: 45px;
  padding-left: 8px;
  color: #fff;
  vertical-align: middle;
  line-height: 55px;
  clip-path: polygon(0 26%, 100% 0, 100% 100%, 0% 100%);
}

.grid-item .grid-footer .bottom {
  position: relative;
  left: 0px;
  bottom: 0px;
  background: rgb(22, 20, 20);
  width: 100%;
  height: 20px;
  color: #fff;
  text-align: right;
  font-size: 14px;
}

/* ---- .grid-item footer ---- */



/* ---- .grid-item lazy-loaded ---- */

.grid-item .lazy-loaded{
  width: 100%;
  height: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center; 
}

.grid-item.bundle .lazy-loaded{
  width: 100%;
  height: 100%;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center; 
}

/* ---- .grid-item lazy-loaded ---- */


.grid-item.width { width: 200px; }
.grid-item.bundle { height: 200px; 

  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center; 
  background-repeat: no-repeat;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  width: 95%;
  background-size: cover;
  background-position: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-container.reservations {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-container.reservations .swiper-slide {
  background-size: cover;
  background-position: center;
  border-radius: 0px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}